<template>
    <div class="container-lesson teacher" id="card_size" ref="card_size">
        <div class="see-more">
            <router-link class="pull-right mr-3" to="/c/all?tab=teacher&teacher_type=expert&sorter=recommend-ASC&type=category">
                {{ $t("lesson.see_more") }}
            </router-link>
        </div>
        <router-link  to="/c/all?tab=teacher&teacher_type=expert&sorter=recommend-ASC&type=category">
            <h5 class="title">{{ $t("lesson.teacher_expert") }}</h5>
        </router-link>
        <carousel
            :items="1"
            :stagePadding="80"
            :margin="12"
            :nav="false"
            :navText="false"
            :dots="false"
            :slideBy="1"
            :rewind="false"
            v-if="items.length > 0"
        >
            <template v-for="(item, key) in items">
                <router-link
                    :key="key"
                    :to="{
                        name: 'teacher-profile',
                        params: { teacherId: item.member_id },
                    }"
                    class="no-decoration"
                >
                    <div class="cimg">
                        <img
                            :src="item.profile_image"
                            :alt="item.profile_image"
                            class="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
                            :title="item.teacher_name"
                        />
                    </div>
                    <div class="description text-center">
                        <div class="info">{{ item.category }}</div>
                        <h5 class="teacher-name">{{ item.teacher_name }}</h5>
                        <div class="introduction custom-text-overflow">
                            {{ item.introduction }}
                        </div>
                        <div class="reviewed-star rate text-left">
              <span class="star">
                <star-rating
                    :rating="Number(item.rated)"
                    :star-size="18"
                    :show-rating="false"
                    :read-only="true"
                />
              </span>
                            <span
                            >{{ item.rated }}
                {{ item.reviewed ? `(${item.reviewed})` : `` }}</span
                            >
                        </div>
                    </div>
                </router-link>
                <!-- <template v-if="key === 1">
                    <router-link v-bind:key="key" to="/to-sell" class="no-decoration">
                        <div class="cimg">
                            <img
                                src="/img/recruitment02.png"
                                alt="Join us"
                            />
                        </div>
                    </router-link>
                </template> -->
            </template>
        </carousel>
    </div>
</template>
<script>
import carousel from "vue-owl-carousel";

export default {
    name: "TeacherExpert",
    components: {
        carousel,
    },
    props: {
        items: [],
    },
    data() {
        return {
            navMenu: [
                '<span class="icon">&#8592;</span>',
                '<span class="icon">&#8594;</span>',
            ],
        };
    },
};
</script>
<style scoped >
    .teacher-ads {
        width: 100%;
        height: 200px;
        border-radius: 10%;
        background-color: orange;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>