<template>
    <div class="row">

        <div class="col-12 pr-0" v-if="lessonTop.lesson.newests.length">
            <LessonNewest :items="lessonTop.lesson.newests" />
        </div>
        <div class="col-12 pr-0" v-if="lessonTop.teacher.experts.length">
            <TeacherExpert :items="lessonTop.teacher.experts" />
        </div>
        <div class="col-12 pr-0" v-if="lessonTop.lesson.ranks.length">
            <LessonRank :items="lessonTop.lesson.ranks" />
        </div>
    </div>
</template>
<script>
import TeacherExpert from './teacher/teacher-expert.vue';
import { mapActions, mapGetters } from 'vuex';
import LessonRank from "@modules/sp/page/top/promotion/lesson/lesson-rank.vue";
import LessonNewest from "@modules/sp/page/top/promotion/lesson/lesson-newest.vue";
export default {
    name: "PromotionTop",
    components: {
        LessonNewest,
        LessonRank,
        TeacherExpert,
    },
    async created() {
        await this.getLessonTop({
            limit: 6
        });
    },
    computed: {
        ...mapGetters({
            lessonTop: "sellLesson/lessonTop"
        })
    },
    methods: {
        ...mapActions({
            getLessonTop: "sellLesson/getLessonTop"
        })
    }
}
</script>