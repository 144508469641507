<template>
    <div class="container-lesson lesson" id="card_size" ref="card_size">
        <div class="see-more">
            <router-link class="pull-right mr-3" to="/c/elementaryschool/juniorhighschool-exam">
                {{ $t("lesson.see_more") }}
            </router-link>
        </div>
        <router-link class="" to="/c/elementaryschool/juniorhighschool-exam">
            <h5 class="title">{{ $t("lesson.newest") }}</h5>
        </router-link>
        <carousel
            :items="1"
            :stagePadding="80"
            :margin="12"
            :nav="false"
            :navText="false"
            :dots="false"
            :slideBy="1"
            :rewind="false"
            v-if="items.length > 0"
        >
            <template v-for="(item, key) in items">
                <router-link
                    :to="{ name: 'lesson.detail', params: { lessonId: item.id } }"
                    class="no-decoration"
                    :key="key"
                >
                    <div class="cimg">
                        <img
                            :src="item.thumbnail"
                            :alt="item.thumbnail"
                            class="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
                            :title="item.title"
                        />
                    </div>
                    <div class="description text-center">
                        <h5 class="custom-text-overflow mt-2">{{ item.title }}</h5>
                        <div class="introduction custom-text-overflow">
                            {{ item.teacher_name }}
                        </div>
                        <div class="price text-left">
                            {{ formatCurrency(item.currency_code, item.price) }}
                        </div>
                        <div class="reviewed-star rate text-left">
                            <span class="star">
                            <star-rating
                                :rating="Number(item.rated)"
                                :star-size="18"
                                :show-rating="false"
                                :read-only="true"
                            />
                            </span>
                            <span>
                                {{ item.rated }}
                                {{ item.reviewed ? `(${item.reviewed})` : `` }}
                            </span>
                        </div>
                    </div>
                </router-link>
            </template>
        </carousel>
    </div>
</template>
<script>
import carousel from "vue-owl-carousel";

export default {
    name: "LessonNewest",
    components: {
        carousel,
    },
    props: {
        items: [],
    },
    data() {
        return {
            navMenu: [
                '<span class="icon">&#8592;</span>',
                '<span class="icon">&#8594;</span>',
            ],
        };
    },
};
</script>