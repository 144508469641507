<template>
    <div class="hero">
      <div class="hero-inner">
        <div class="hero-inner--slider">
          <div class="swiper" ref="swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, key) in sliders" :key="key">
                <a
                  :href="item.url"
                  :target="item.open_in === 'new' ? '_blank' : '_self'"
                  rel="noopener"
                  ><img
                    :src="item.image_url"
                    :alt="item.image_name"
                    width="980"
                    height="552"
                /></a>
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
        <div class="hero-inner--navi">
          <nav>
            <ul class="hero-inner--navi--list">
              <li class="list navi-link">
                <a href="/c/infant/elementaryschool-exam"
                  ><img
                    src="/img/top/icon/ico-s01.png"
                    width="300"
                    height="300"
                    alt="小学受験 画像"
                  /><span>小学受験</span></a
                >
              </li>
              <li class="list navi-link">
                <a
                  href="/c/elementaryschool/juniorhighschool-exam"
                  ><img
                    src="/img/top/icon/ico-s02.png"
                    width="300"
                    height="300"
                    alt="中学受験 画像"
                  /><span>中学受験</span></a
                >
              </li>
              <li class="list navi-link">
                <a href="/c/juniorhighschool/highschool-exam"
                  ><img
                    src="/img/top/icon/ico-s03.png"
                    width="300"
                    height="300"
                    alt="高校受験 画像"
                  /><span>高校受験</span></a
                >
              </li>
              <li class="list navi-link">
                <a href="/c/seniorhighschool/university-exam"
                  ><img
                    src="/img/top/icon/ico-s04.png"
                    width="300"
                    height="300"
                    alt="大学受験 画像"
                  /><span>大学受験</span></a
                >
              </li>
              <li class="list navi-link">
                <a href="/c/all"
                  ><img
                    src="/img/top/icon/ico-consultation.png"
                    width="300"
                    height="300"
                    alt="最新相談 画像"
                  /><span>最新相談</span></a
                >
              </li>
              <li class="list navi-link">
                <a href="/rank/all"
                  ><img
                    src="/img/top/icon/ico-ranking.png"
                    width="300"
                    height="300"
                    alt="ランキング 画像"
                  /><span>ランキング</span></a
                >
              </li>
              <li class="list navi-info navi-firsttime">
                <a href="/my-page/"
                  ><img
                    src="/img/top/icon/ico-my.png"
                    width="300"
                    height="300"
                    alt="マイページ 画像"
                  /><span>マイページ</span></a
                >
              </li>
              <li class="list navi-info navi-mypage">
                <a href="/guide"
                  ><img
                    src="/img/top/icon/ico-beginner.png"
                    width="300"
                    height="300"
                    alt="初めての方へ 画像"
                  /><span>初めての方へ</span></a
                >
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="hero-bg">
        <img
          src="/img/top/bg/ph01.jpg"
          alt="リセマム相談online 画像"
        />
        <img
          src="/img/top/bg/ph02.jpg"
          alt="リセマム相談online 画像"
        />
        <img
          src="/img/top/bg/ph03.jpg"
          alt="リセマム相談online 画像"
        />
        <img
          src="/img/top/bg/ph04.jpg"
          alt="リセマム相談online 画像"
        />
        <img
          src="/img/top/bg/ph05.jpg"
          alt="リセマム相談online 画像"
        />
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  
  export default {
    name: "slider",
    data() {
      return {
        sliders: [],
      };
    },
    computed: {
      ...mapGetters({
        topPage: "common/topPage",
      }),
    },
    created() {
      this.sliders = this.topPage.slider;
    },
    mounted() {
      setTimeout(() => {
        new window.$swiper(".swiper", {
          direction: "horizontal",
          loop: true,
          autoplay: {
            delay: 5000, //5000ミリ秒停止
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        });
      }, 500);
    },
    watch: {
      topPage(data) {
        this.sliders = data.slider;
      },
    },
  };
  </script>
  